import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  batch,
  createContext,
  JSX,
  onMount,
  useContext,
  createEffect,
  createSignal,
} from "solid-js";
import { createStore } from "solid-js/store";
import { isServer } from "solid-js/web";
import { clientRepo } from "~/server/apis/client_repo";
import {
  getSquidContextData,
  SquidContextData,
} from "~/server/data/squid_route_data";
import { SquidAuthResponse } from "~/server/types/auth";
import { SquidProductWithName } from "~/server/types/order";
import { LocalStorageKey } from "~/types";
import LocalStorageUtil from "~/utils/local_storage";

type SquidContextProviderProps = {
  children: JSX.Element;
};

type ProductDetail = {
  logoUrl: string;
  brandName: string;
};

export type CartProduct = {
  detail: ProductDetail;
  productId: string;
  amount: number;
  denomination: number;
  quantity: number;
};

export type Cart = {
  totalAmount: number;
  totalQuantity: number;
  totalDiscount: number;
  products: CartProduct[];
  proformaInvoiceId?: string;
};

type SquidDataStore = {
  cart: Cart;
  userProfile: { name: string; logo: string; email: string; phone: string };
  walletBalance: number;
  businessDetails: SquidAuthResponse;
};

type SquidContext = {
  squidDataStore: SquidDataStore;
  addToCart: (newProduct: CartProduct) => void;
  updateQuantity: (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => void;
  clearCart: () => void;
  updatePIInfo: (id: string) => void;
  clearAndAddProductsToCart: (products: SquidProductWithName[]) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  refetchBalance: () => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  updateDefaultAddress: (address: string) => Promise<void>;
};

const SquidContext = createContext<SquidContext>();

const getSquidContextData$C = cache(getSquidContextData, "squid-route-data");

export function SquidContextProvider(props: SquidContextProviderProps) {
  const routeData: Accessor<SquidContextData | undefined> =
    createAsync<SquidContextData>(() => getSquidContextData$C());

  const [simulateErrorMessage, setSimulateErrorMessage] = createSignal<
    string | undefined
  >(undefined);

  const [loadingSimulateData, setLoadingSimulateData] = createSignal(false);

  const [squidDataStore, setSquidDataStore] = createStore<SquidDataStore>({
    cart: {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [] as CartProduct[],
      proformaInvoiceId: undefined as undefined | string,
    },
    userProfile: {
      name: "Team",
      logo: "",
      email: "",
      phone: "",
    },
    walletBalance: 0,
    businessDetails: {
      id: "",
      status: "",
      businessName: "",
      clientId: "",
      details: {
        gstNumber: "",
        address: {
          address1: "",
          address2: "",
          place: "",
          state: "",
          pincode: "",
          id: "",
        },
        addresses: [],
        defaultAddressId: "",
        gstCertificateUrl: "",
      },
    },
  });

  createEffect(() => {
    if (routeData()) {
      setSquidDataStore("userProfile", {
        name: routeData()?.userProfile?.name ?? "Team",
        logo: routeData()?.userProfile?.logo ?? "",
        email: routeData()?.userProfile?.email ?? "",
        phone: routeData()?.userProfile?.phone ?? "",
      });

      setSquidDataStore("businessDetails", {
        businessName: routeData()?.businessDetails.businessName ?? "",
        clientId: routeData()?.businessDetails.clientId ?? "",
        status: routeData()?.businessDetails.status ?? "",
        id: routeData()?.businessDetails.id ?? "",
        details: routeData()?.businessDetails.details,
      });

      setSquidDataStore("walletBalance", routeData()?.balance ?? 0);
    }
  });

  onMount(() => {
    const cartStr = LocalStorageUtil.getItem<string>(LocalStorageKey.SquidCart);
    if (cartStr) {
      const cart = JSON.parse(cartStr) as Cart;
      setSquidDataStore("cart", cart);
    }
  });

  const simulateAndUpdateCartDiscount = async () => {
    const simulatePayload = {
      totalAmount: squidDataStore.cart.totalAmount,
      products: squidDataStore.cart.products.map((product) => ({
        productId: product.productId,
        amount: product.amount,
        denominationDetails: [
          {
            denomination: product.denomination,
            quantity: product.quantity,
          },
        ],
      })),
    };

    try {
      setSimulateErrorMessage(undefined);
      setLoadingSimulateData(true);
      const response = await clientRepo.simulateCart(simulatePayload);
      setSquidDataStore(
        "cart",
        "totalDiscount",
        response.data[0].totalDiscount
      );
      setSquidDataStore("cart", "totalAmount", response.data[0].totalAmount);

      LocalStorageUtil.setItem(
        LocalStorageKey.SquidCart,
        JSON.stringify(squidDataStore.cart)
      );
    } catch (error: any) {
      setSimulateErrorMessage(error.message);
      console.error("Error simulating cart:", error);
    }
    setLoadingSimulateData(false);
  };

  const addAProductToCart = async (newProduct: CartProduct) => {
    const existingProductIndex = squidDataStore.cart.products.findIndex(
      (product) =>
        product.productId === newProduct.productId &&
        product.denomination === newProduct.denomination
    );

    if (existingProductIndex !== -1) {
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "quantity",
        (quantity) => quantity + newProduct.quantity
      );
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "denomination",
        () => newProduct.denomination
      );
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "amount",
        (amount) => amount + newProduct.amount
      );
    } else {
      setSquidDataStore("cart", "products", (products) => [
        ...products,
        newProduct,
      ]);
    }

    setSquidDataStore(
      "cart",
      "totalQuantity",
      squidDataStore.cart.products.length
    );
    setSquidDataStore(
      "cart",
      "totalAmount",
      (amount) => amount + newProduct.amount
    );
    await simulateAndUpdateCartDiscount();
  };

  // This is being used by PI complete order func. will mostly have correct data so
  // we set data before simulating for routing to payment

  const clearAndAddProductsToCart = async (
    products: SquidProductWithName[]
  ) => {
    clearCart();
    products.map((product) => {
      product.denominationDetails.length == 1
        ? setSquidDataStore("cart", "products", (products) => [
            ...products,
            {
              productId: product.productId,
              amount: product.amount,
              denomination: product.denominationDetails[0].denomination,
              quantity: product.denominationDetails[0].quantity,
              detail: {
                logoUrl: "",
                brandName: product.productName,
              },
            },
          ])
        : product.denominationDetails.map((pdt) => {
            setSquidDataStore("cart", "products", (products) => [
              ...products,
              {
                productId: product.productId,
                amount: pdt.denomination * pdt.quantity,
                denomination: pdt.denomination,
                quantity: pdt.quantity,
                detail: {
                  logoUrl: "",
                  brandName: product.productName,
                },
              },
            ]);
          });
      setSquidDataStore(
        "cart",
        "totalAmount",
        (amount) => amount + product.amount
      );
    });

    setSquidDataStore(
      "cart",
      "totalQuantity",
      squidDataStore.cart.products.length
    );

    await simulateAndUpdateCartDiscount();
  };

  // This is used by denominationDetail modal to add multiple products to cart
  // To avoid multiple simulation calls, we simulate the cart only once
  // and only update cart if simulate gives a successful response
  const addProductsToCart = async (products: SquidProductWithName[]) => {
    // Create a temporary cart with the new products
    const tempCart = {
      ...squidDataStore.cart,
      products: [...squidDataStore.cart.products],
    };

    products.forEach((product) => {
      if (product.denominationDetails.length === 1) {
        const newProduct = {
          productId: product.productId,
          amount: product.amount,
          denomination: product.denominationDetails[0].denomination,
          quantity: product.denominationDetails[0].quantity,
          detail: {
            logoUrl: product.logoUrl ?? "",
            brandName: product.productName,
          },
        };

        const existingProductIndex = tempCart.products.findIndex(
          (p) =>
            p.productId === newProduct.productId &&
            p.denomination === newProduct.denomination
        );

        if (existingProductIndex !== -1) {
          tempCart.products[existingProductIndex].quantity +=
            newProduct.quantity;
          tempCart.products[existingProductIndex].amount += newProduct.amount;
        } else {
          tempCart.products.push(newProduct);
        }
      } else {
        product.denominationDetails.forEach((pdt) => {
          const newProduct = {
            productId: product.productId,
            amount: pdt.denomination * pdt.quantity,
            denomination: pdt.denomination,
            quantity: pdt.quantity,
            detail: {
              logoUrl: product.logoUrl ?? "",
              brandName: product.productName,
            },
          };

          const existingProductIndex = tempCart.products.findIndex(
            (p) =>
              p.productId === newProduct.productId &&
              p.denomination === newProduct.denomination
          );

          if (existingProductIndex !== -1) {
            tempCart.products[existingProductIndex].quantity +=
              newProduct.quantity;
            tempCart.products[existingProductIndex].amount += newProduct.amount;
          } else {
            tempCart.products.push(newProduct);
          }
        });
      }
    });

    tempCart.totalQuantity = tempCart.products.length;
    tempCart.totalAmount = tempCart.products.reduce(
      (total, product) => total + product.amount,
      0
    );

    // Simulate the cart with the new products
    const simulatePayload = {
      totalAmount: tempCart.totalAmount,
      products: tempCart.products.map((product) => ({
        productId: product.productId,
        amount: product.amount,
        denominationDetails: [
          {
            denomination: product.denomination,
            quantity: product.quantity,
          },
        ],
      })),
    };

    try {
      setSimulateErrorMessage(undefined);
      setLoadingSimulateData(true);
      const response = await clientRepo.simulateCart(simulatePayload);

      // Update the actual cart if simulation is successful
      setSquidDataStore(
        "cart",
        "totalDiscount",
        response.data[0].totalDiscount
      );
      setSquidDataStore("cart", "totalAmount", response.data[0].totalAmount);
      setSquidDataStore("cart", "products", tempCart.products);
      setSquidDataStore("cart", "totalQuantity", tempCart.totalQuantity);

      LocalStorageUtil.setItem(
        LocalStorageKey.SquidCart,
        JSON.stringify(squidDataStore.cart)
      );
    } catch (error: any) {
      setSimulateErrorMessage(error.message);
      console.error("Error simulating cart:", error);
    }
    setLoadingSimulateData(false);
  };

  const updateQuantity = async (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => {
    const existingProductIndex = squidDataStore.cart.products.findIndex(
      (product) =>
        product.productId === productId && product.denomination === denomination
    );

    if (existingProductIndex !== -1) {
      const existingProduct =
        squidDataStore.cart.products[existingProductIndex];
      const quantityDifference = newQuantity - existingProduct.quantity;
      const amountDifference = quantityDifference * denomination;

      batch(() => {
        if (newQuantity === 0) {
          setSquidDataStore("cart", "products", (products) =>
            products.filter((_, index) => index !== existingProductIndex)
          );
        } else {
          setSquidDataStore(
            "cart",
            "products",
            existingProductIndex,
            "quantity",
            newQuantity
          );
          setSquidDataStore(
            "cart",
            "products",
            existingProductIndex,
            "amount",
            existingProduct.denomination * newQuantity
          );
        }

        const updatedTotalAmount = squidDataStore.cart.products.reduce(
          (total, product) =>
            total +
            (product.productId === productId &&
            product.denomination === denomination
              ? denomination * newQuantity
              : product.amount),
          0
        );

        setSquidDataStore(
          "cart",
          "totalQuantity",
          squidDataStore.cart.products.length
        );
        setSquidDataStore("cart", "totalAmount", updatedTotalAmount);
      });

      if (squidDataStore.cart.products.length === 0) {
        setSquidDataStore("cart", "totalQuantity", 0);
        LocalStorageUtil.removeItem(LocalStorageKey.SquidCart);
        return;
      }

      await simulateAndUpdateCartDiscount();
    }
  };

  const updateDefaultAddress = async (addressId: string) => {
    const previousAddressId =
      squidDataStore.businessDetails.details.defaultAddressId;

    try {
      setSquidDataStore(
        "businessDetails",
        "details",
        "defaultAddressId",
        addressId
      );

      await clientRepo.updateBusinessAddress(addressId);
    } catch (error: any) {
      console.error("Error updating default address:", error.message);

      setSquidDataStore(
        "businessDetails",
        "details",
        "defaultAddressId",
        previousAddressId
      );

      // Re-throw to allow components to handle UI feedback if needed
      throw error;
    }
  };

  const updatePIInfo = (id: string) => {
    setSquidDataStore("cart", {
      proformaInvoiceId: id,
    });
  };

  const clearCart = () => {
    setSquidDataStore("cart", {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [],
      proformaInvoiceId: undefined,
    });

    // Clear cart is called in multiple places in the components
    // To ensure we don't error out on the server with
    // ReferenceError: localStorage is not defined
    if (!isServer) {
      LocalStorageUtil.removeItem(LocalStorageKey.SquidCart);
    }
  };

  const refetchBalance = async () => {
    const balance = await clientRepo.getSquidWalletBalance();
    if (balance) {
      setSquidDataStore("walletBalance", balance.balance);
    }
  };

  return (
    <SquidContext.Provider
      value={{
        squidDataStore,
        addToCart: addAProductToCart,
        updateQuantity,
        clearCart,
        updatePIInfo,
        clearAndAddProductsToCart,
        addProductsToCart,
        refetchBalance,
        simulateErrorMessage,
        loadingSimulateData,
        updateDefaultAddress,
      }}
    >
      {props.children}
    </SquidContext.Provider>
  );
}

export function useSquidContext() {
  return useContext(SquidContext)!;
}
